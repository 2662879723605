import axios from 'axios'
import config from '../config';

export const calculateMail = (numberOfEnvelopes,
    openAndScan,
    numberOfPages,
    majorityForwarded,
    fowardDomestic,
    howOftenForwarded,
    previewScan
) => {
    return new Promise((resolve, reject) => {
        console.log("config", config);
        axios({
            url: `${config.apiUrl}/api/Public/Calculators/Mail`,
            headers: { 'content-type': 'application/json' },
            params: {
                numberOfEnvelopes,
                openAndScan,
                numberOfPages,
                majorityForwarded,
                fowardDomestic,
                howOftenForwarded,
                previewScan
            },
            method: 'GET'
        })
            .then((response) => {
                console.log("response", response);
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            })
    });
};

export const calculateParcelForward = (shippingQuoteModel) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${config.apiUrl}/api/Public/Calculators/ForwardEstimator`,
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            data: shippingQuoteModel
        })
            .then((response) => {
                resolve(response);
            })
            .catch((response) => {
                reject(response);
            });

    });
}