import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import styled from "styled-components";
import countryList from '../json/country.json';
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";
import * as calculatorService from '../services/calculatorService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from '../assets/styles/parcel-forwarding-calculator.module.css';
import Helmet from "react-helmet"

const StyledTable = styled.table`
  thead,
  tr,
  tbody {
    display: block;
  }
  thead tr .sin-title-box {
    padding: 0 20px;
    height: 60px;
    color: #19191b;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.56px;
  }
  .title-col {
    min-width: 290px;
  }
  .basic-col,
  .standard-col,
  .premium-col,
  .ent-col {
    min-width: 195px;
  }

  .colored-row {
    background: #f7f7fb;
    border-top: 2px solid #fff;
    &:first-child {
      border-top: 0;
    }
    .sin-box.title-col {
      color: #19191b;
      font-weight: 700;
    }
    .sin-box {
      position: relative;
      padding: 0 20px;
      height: 65px;
      color: #696871;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: -0.56px;
      .table-icon {
        font-size: 20px;
        &.neg {
          color: #f04037;
        }
        &.pos {
          color: #67d216;
        }
      }
      &:before {
        content: "";
        background: #fff;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        width: 1px;
      }
    }
  }
`;

const ButtonSm = styled(Button)`
  width: 167px;
  min-width: auto;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  &:hover,
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.secondary}`} !important;
    background-color: ${({ theme }) => `${theme.colors.secondary}`} !important;
    color: #fff;
  }
`;

const Calculator = () => {
  const [result, setResults] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forwardCost, setForwardCost] = useState();
  const [shippingQuoteModel, setShippingQuoteModel] = useState({
    length: '',
    width: '',
    height: '',
    weight: '',
    mailType: 'Parcel',
    countryCode: 'US',
    postcode: ''
  });

  const onCountryChange = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    var country = e.nativeEvent.target[index].text;
    var countryCode = e.target.value;
    setShippingQuoteModel({ ...shippingQuoteModel, country: country, countryCode: countryCode });
  };

  const setShippingQuote = (propertyName, value) => {
    setShippingQuoteModel({ ...shippingQuoteModel, [propertyName]: value });
  };

  const onCalculate = async () => {
    // if (validate()) {
    setLoading(true);
    var response = await calculatorService.calculateParcelForward(shippingQuoteModel);
    setForwardCost(response.data);
    setLoading(false);
    //}
  };

  const validate = () => {
    let errorMessage = [];
    if (!shippingQuoteModel.length) {
      errorMessage.push('Length is required');
    }
    if (!shippingQuoteModel.width) {
      errorMessage.push('Width is required');
    }
    if (!shippingQuoteModel.height) {
      errorMessage.push('Height is required');
    }
    if (!shippingQuoteModel.weight) {
      errorMessage.push('Weigth is required');
    }
    if (!shippingQuoteModel.postcode) {
      errorMessage.push('Postcode is required');
    }
    if (!shippingQuoteModel.country) {
      errorMessage.push('Country is required');
    }
    if (shippingQuoteModel.weight > 500 && shippingQuoteModel.mailType === "Letter") {
      errorMessage.push('Letters can not be over 500 grams in');
    }


    if (errorMessage.length > 0) {
      setError(errorMessage);
    } else {
      setError([]);
    }
    return errorMessage.length === 0;
  };

  const renderCostTable = () => {
    if (!forwardCost) {
      return "";
    }

    let rows = {};
    for (let i = 0; i < forwardCost.length; i++) {
      if (!rows[forwardCost[i].service]) {
        rows[forwardCost[i].service] = {
          shipperLogo: forwardCost[i].shipperLogo,
          plans: []
        };
      }
      rows[forwardCost[i].service].plans.push({
        plan: forwardCost[i].planName,
        price: forwardCost[i].price
      })
    }

    var keys = Object.keys(rows);
    keys.sort();

    let rowHtml = [];
    let titleColumn = [<th key={"_tileRow"}></th>];
    for (let i = 0; i < rows[keys[0]].plans.length; i++) {
      titleColumn.push(<th key={rows[keys[0]].plans[i].plan}>{rows[keys[0]].plans[i].plan}</th>)
    }

    rowHtml.push(<thead><tr>{titleColumn}</tr></thead>)
    let bodyHtml = [];
    for (let i = 0; i < keys.length; ++i) {
      let service = keys[i];
      let columnHtml = [];
      columnHtml.push(<td><img style={{ width: "150px", marginRight: "10px" }} src={`/${rows[service].shipperLogo}`}></img> {service}</td>)
      for (let j = 0; j < rows[service].plans.length; j++) {
        columnHtml.push(<td key={`${i}${j}`}>{rows[service].plans[j].price.toLocaleString('en-AU', {
          style: 'currency',
          currency: 'AUD',
        })}</td>)
      }
      bodyHtml.push(<tr>{columnHtml}</tr>)
    }
    rowHtml.push(<tbody>{bodyHtml}</tbody>);

    return (
      <Table responsive>{rowHtml}</Table>
    )
  }



  return (
    <>
      <Helmet>
        <title>HotSnail - Parcel Forwarding Calculator</title>
        <meta name="title" content="HotSnail - Parcel Forwarding Calculator" />
        <meta name="description" content="HotSnail - Parcel Forwarding calculator - calculate the price of mails forwarding from Australia " />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hotsnail.com.au/" />
        <meta property="og:title" content="HotSnail - Parcel Forwarding Calculator" />
        <meta property="og:description" content="HotSnail - Parcel Forwarding calculator - calculate the price of mails forwarding from Australia " />
        <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://hotsnail.com.au/" />
        <meta property="twitter:title" content="HotSnail - Parcel Forwarding Calculator" />
        <meta property="twitter:description" content="HotSnail - Parcel Forwarding calculator - calculate the price of mails forwarding from Australia " />
        <meta property="twitter:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png" />
      </Helmet>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="6">
                <Title variant="hero">Parcel Fowarding estimator</Title>
              </Col>
            </Row>

            <Section className="pt-0">
              <Container>
                <div
                  css={`
                    padding-bottom: 30px;
                  `}
                >

                  <>
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Label>
                          Dimension (in centermetres)
                        </Form.Label>
                        <Form inline>

                          <Form.Control
                            type="number"
                            placeholder="length"
                            value={shippingQuoteModel.length}
                            onChange={(e) => setShippingQuote('length', e.target.value)}
                            className={styles.dimensionInput}
                          >

                          </Form.Control>

                          <Form.Control
                            type="number"
                            className="ml-1"
                            placeholder="width"
                            value={shippingQuoteModel.width}
                            onChange={(e) => setShippingQuote('width', e.target.value)}
                            className={styles.dimensionInput}

                          >
                          </Form.Control>

                          <Form.Control
                            type="number"
                            className="ml-1"
                            placeholder="height"
                            value={shippingQuoteModel.height}
                            onChange={(e) => setShippingQuote('height', e.target.value)}
                            className={styles.dimensionInput}

                          ></Form.Control>
                        </Form>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Label>
                          Weight (in grams)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="weight"
                          value={shippingQuoteModel.weight}
                          onChange={(e) => setShippingQuote('weight', e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={12} md={4}>
                        <Form.Label>
                          Letter or Parcel
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={shippingQuoteModel.mailType}
                          onChange={(e) => setShippingQuote('mailType', e.target.value)}
                        >
                          <option>Parcel</option>
                          <option>Letter</option>
                        </Form.Control>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Label>
                          Country
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={shippingQuoteModel.countryCode}
                          onChange={onCountryChange}
                        >
                          {countryList.map((country) => (
                            <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Label>
                          Postcode
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="post code"
                          value={shippingQuoteModel.postcode}
                          onChange={(e) => setShippingQuote('postcode', e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={12}>
                        <Button onClick={onCalculate}>{loading ? <FontAwesomeIcon icon="spinner" spin /> : "Calculate"} </Button>
                      </Col>
                    </Row>
                  </>


                  {(result && result.length > 0) &&
                    <StyledTable>
                      <thead>
                        <tr>
                          <th scope="col" className="title-col sin-title-box"></th>
                          {result.map(a => <th scope="col" className="basic-col sin-title-box">{a.planName}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="colored-row">
                          <th scope="row" className="title-col sin-box">
                            Estimate (per month)
                          </th>

                          {result.map(a => <td className="basic-col sin-box">${a.price.toFixed(2)}</td>)}
                        </tr>
                        <tr>
                          <td colSpan="4"><small>Price is estimate only &amp; excludes once off setup fee</small></td>
                        </tr>
                      </tbody>
                    </StyledTable>
                  }
                </div>
                {renderCostTable()}
              </Container>
            </Section>


          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default Calculator;
