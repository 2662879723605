export default {
    get apiUrl() {
        console.log("process.env.NODE_ENV", process.env.NODE_ENV);
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return "";            
        } else {
            return "https://members.hotsnail.com.au";
        }
    }
}
